<template>
  <full-page :options="options" ref="page">
    <div class="section">
      <div class="box1">
        <div class="swiperBanner" v-if="bannerList.length>0">
          <swiper class="swiper-Box" :options="swiperOption" >
            <!-- 
            v-if="info.length > 0" -->
            <swiper-slide
              class="swiper-slide"
              v-for="(item, index) in bannerList"
              :key="index"
            >
              <!-- v-if="height && innerWidth > 1024 && item.video == ''" -->
              <div class="imgBox">
                <img
                  class="swiper-img"
                  :src="item.logo"
                  :style="{ height: height }"
                />
                <div class="bannerText animate__animated animate__fadeInUp" v-if="item.title" style="display:block">
                  <span>{{ item.title }}</span>
                  <span>{{ item.title2 }}</span>
                  <span class="banner_english">{{ item.en_title }}</span>
                  <!-- <img src="../../../static/img/zi.png" alt="" /> -->
                </div>
              </div>
            </swiper-slide>
            <div class="swiper_jt">
              <div class="swiper-button-prev swiper_btn"></div>
              <div class="swiper-button-next swiper_btn"></div>
            </div>
            <!-- <div class="swiper-pagination" slot="pagination"></div> -->
          </swiper>
          <div class="mouse_box">
            <div class="mouse_line"></div>
            <img src="../../../static/img/icon2.png" alt="" class="mouse_img" />
            <div class="mouse_span">鼠标下滑</div>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <!-- <div class="modular2"> -->
        <div class="modular2_box mk_box1">
          <div class="content_w">
            <div class="modular2_txt mk1">{{ content0.title }}</div>
            <div class="modular2_con mk1">
              {{ content0.gai }}
            </div>
            <ul class="modular2_ul mk1">
              <li>
                <img :src="content1[0].pic" class="modular_bg" alt="" />
                <div>
                  <div class="modular_num">
                    <count-to
                      :start-val="0"
                      :end-val="Number(num1)"
                      :duration="4000"
                      class="text-style"
                    ></count-to>
                    <span style="font-size: 30px; margin-top: 2px">{{
                      content1[0].title3
                    }}</span>
                  </div>
                  <span>{{ content1[0].title }}</span>
                </div>
              </li>
               <li>
                <img :src="content1[1].pic" class="modular_bg" alt="" />
                <div>
                  <div class="modular_num">
                    <count-to
                      :start-val="0"
                      :end-val="Number(num2)"
                      :duration="3000"
                      class="text-style"
                    ></count-to>
                    <span style="font-size: 30px; margin-top: 2px">{{
                      content1[1].title3
                    }}</span>
                  </div>
                  <span>{{ content1[1].title }}</span>
                </div>
              </li>
               <li>
                <img :src="content1[2].pic" class="modular_bg" alt="" />
                <div>
                  <div class="modular_num">
                    <count-to
                      :start-val="0"
                      :end-val="Number(num3)"
                      :duration="1500"
                      class="text-style"
                    ></count-to>
                    <span style="font-size: 30px; margin-top: 2px">{{
                      content1[2].title3
                    }}</span>
                  </div>
                  <span>{{ content1[2].title }}</span>
                </div>
              </li>
              
            </ul>
            <div class="modular2_bt mk1" @click="goAbout">
              <div class="xian"></div>
              <span>查看更多</span>
              <div class="yuan"></div>
              <img src="../../../static/img/rjt.svg" />
            </div>
          </div>
        </div>
        <!-- style="height:90vh"  -->
        <img :src="content0.pic" class="modular_bg mk1_tu modular_bgtu1" alt=""  />
      <!-- </div> -->
    </div>
    <div class="section" style="position: relative;">
      <div class="modular2_box mk_box2 mt15">
        <div class="content_w">
          <div class="modular2_txt mk2">{{content2.title2}}</div>
          <ul class="modular3_ul mk2" v-if="innerWidth>1024">
            <li
              v-for="(item, index) in company"
              :key="index"
              :class="index === indexa ? 'ziActive' : ''"
              @click="ziFn(index)"
            >
              {{ item.title }}
              <div class="mk3_line"></div>
            </li>
          </ul>
          <ul class="modular3_ul mk2" v-if="innerWidth<=1024">
              <swiper :options="swiperOptions3" >
                <swiper-slide v-for="(item, index) in company" :key="index" >
                     <div class="modular3_li"  :class="index === indexa ? 'ziActive' : ''" @click="ziFn(index)">
                      {{ item.title }}
                      <div class="mk3_line"></div>
                     </div>
                </swiper-slide>
              </swiper>
          </ul>
          <div
            class="zi_gs mk2 mk_box2"
            v-for="(item, index) in company"
            :key="index"
            v-show="index == indexa"
          >
            <swiper class="swiper-Box zi_swiper" :options="swiperOption1">
              <swiper-slide
                class="swiper-slide"
                v-for="(itema, indexb) in item.detail"
                :key="indexb"
              >
                <div class="modular3_ny">
                  <div class="modular3_txt">{{ itema.title }}</div>
                  <div class="modular3_wz">
                    {{ itema.describe }}
                  </div>
                </div>
              </swiper-slide>
              <div class="swiper-pagination1" slot="pagination"></div>
            </swiper>
            <div class="modular2_bt zi_bt zgs_bt" @click="goSub(indexa)" >
                  <div class="xian"></div>
                  <span>查看更多</span>
                  <div class="yuan"></div>
                  <img src="../../../static/img/rjt.svg" />
              </div>
          </div>
        </div>
      </div>
      <div v-for="(item, index) in company" :key="index" class="section3_tu modular_bgtu1">
        <img
          :src="item.pic"
          class="modular_bg mk3_tu"
          alt=""
          v-show="indexa == index"
           style="height:100vh"
        />
      </div>
    </div>


    <!-- <img src="../../../static/img/bg3.jpg" class="modular_bg mk2_tu" alt="" /> -->
    <div class="section">
      <div class="modular2" v-if="innerWidth>768">
        <div class="modular2_box modular4_box mk_box3">
          <div class="pos">
            <div class="modular2_txt modular4_txt mk3"></div>
            <ul class="modular4_ul">
              <li
                class="mk3 hover_a"
                v-for="(item, index) in fields"
                :key="index"
                @mouseenter="ImgFn(index)"
              >
                <div class="modular4_cont">
                  <img :src="item.pic3" class="modular_bg" alt="" />
                  <span>{{ item.title }}</span>
                  <div class="modular4_tit">
                    <div class="line_md4"></div>
                    {{ item.title2 }}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div v-for="(item, index) in fields" :key="index">
          <img
            :src="item.pic4"
            class="modular_bg mk3_tu"
            alt=""
            v-show="current == index"
          />
        </div>
      </div>
      <div class="modular2 modular_bgtu1" v-if="innerWidth<=768">
        <div class="sub_box_box">
          <div class="sub_box">
            <div class="modular2_box modular4_box mk_box3">
              <div class="pos">
                <div class="modular2_txt modular4_txt mk3"></div>
                <ul class="modular4_ul">
                  <li
                    class="mk3 hover_a"
                    v-for="(item, index) in fields"
                    :key="index"
                    @click="ImgFn(index)"
                    v-if="index<2"
                  >
                    <div class="modular4_cont">
                      <img :src="item.pic3" class="modular_bg" alt="" />
                      <span>{{ item.title }}</span>
                      <div class="modular4_tit">
                        <div class="line_md4"></div>
                        {{ item.title2 }}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
           
          </div>
          <div  class="sub_box sub_box1">
            <div class="modular2_box modular4_box mk_box3">
              <div class="pos">
                <div class="modular2_txt modular4_txt mk3"></div>
                <ul class="modular4_ul">
                  <li
                    class="mk3 hover_a"
                    v-for="(item, index) in fields"
                    :key="index"
                    @click="ImgFn(index)"
                    v-if="index>=2"
                  >
                    <div class="modular4_cont">
                      <img :src="item.pic3" class="modular_bg" alt="" />
                      <span>{{ item.title }}</span>
                      <div class="modular4_tit">
                        <div class="line_md4"></div>
                        {{ item.title2 }}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
           
          </div>
        </div>
        <div v-for="(item, index) in fields" :key="index" class="ph_subimg modular_bgtu1">
          <img
            :src="item.pic4"
            class="modular_bg mk3_tu modular_bgtu1"
            alt=""
            v-show="current == index"
          />
        </div>
      </div>
    </div>
    <div class="section modular2H">
        <div class="modular2_box mk_box4">
          <div class="content_w modular5_box">
            <div class="modular2_txt mk4">{{ content2.title }}</div>
            <ul class="modular5_ul mk4">
              <li v-for="(item, index) in news" :key="index" @click="goNews(item.id)">
                <div class="news_time">
                  {{ item.month }}-{{ item.day }}<br /><span>{{
                    item.year
                  }}</span>
                </div>
                <div class="news_txt">{{ item.title }}</div>
                <div class="news_cont">
                  {{ item.describe }}
                </div>
                <div class="news_img animation_img">
                  <img :src="item.pic" />
                </div>
              </li>
            </ul>
            <div class="modular2_bt new_bt" @click="goNewsList">
              <div class="xian"></div>
              <span>查看更多</span>
              <div class="yuan"></div>
              <img src="../../../static/img/rjt1.svg" />
            </div>
          </div>
        </div>

        <img :src="content2.pic" class="modular_bg mk4_tu modular_bgtu1" alt="" />
    </div>
    <div class="section footH">
      <div class="footbox">
        <div class="footer content_w">
          <div class="footlt">
            <div class="footlogo">
              <img src="../../../static/img/logo.png" alt="" />
            </div>
            <ul class="footma_ul">
              <li>
                <img src="../../../static/img/ft_icon1.png" alt="" />
                <span>
                  {{ webInfo.title4 }}
                </span>
              </li>
              <li>
                <img src="../../../static/img/ft_icon2.png" alt="" />
                {{ webInfo.title2 }}
              </li>
              <li>
                <img src="../../../static/img/ft_icon3.png" alt="" />
                {{ webInfo.title3 }}
              </li>
            </ul>
          </div>
          <ul class="footul">
            <li>
              <router-link :to="{name:'about',query:{navctiveIndex:0}}" class="litop" tag="div">关于我们</router-link>
              <div class="foot_line"></div>
              <router-link :to="{name:'about',query:{navctiveIndex:0}}" tag="div">公司介绍</router-link>
              <!-- <router-link :to="{name:'about',query:{navctiveIndex:0}}" tag="div">股东优势</router-link> -->
              <router-link :to="{name:'about',query:{navctiveIndex:1}}" tag="div">组织架构</router-link>
              <!-- <router-link :to="{name:'about',query:{navctiveIndex:0}}" tag="div">合作伙伴</router-link> -->
            </li>
            <li>
              <router-link :to="{name:'subsidiray',query:{navctiveIndex:0}}" class="litop" tag="div">子公司</router-link>
              <div class="foot_line"></div>
              <router-link :to="{name:'subsidiray',query:{navctiveIndex:0}}" tag="div">业务主体子公司</router-link>
              <router-link :to="{name:'subsidiray',query:{navctiveIndex:1}}" tag="div">供应链服务公司</router-link>
              <router-link :to="{name:'subsidiray',query:{navctiveIndex:2}}" tag="div">产业链实体投资</router-link>
              <router-link :to="{name:'subsidiray',query:{navctiveIndex:3}}" tag="div">境外贸易公司</router-link>
            </li>
            <li>
             <router-link :to="{name:'field',query:{fieldIndex:1}}" class="litop" tag="div">业务领域</router-link>
              <div class="foot_line"></div>
              <router-link :to="{name:'field',query:{fieldIndex:0}}" tag="div">能源化工</router-link>
              <router-link :to="{name:'field',query:{fieldIndex:1}}" tag="div">有色金属</router-link>
              <router-link :to="{name:'field',query:{fieldIndex:2}}" tag="div">农产品/软商品</router-link>
              <router-link :to="{name:'field',query:{fieldIndex:3}}" tag="div">工业品</router-link>
            </li>
            <li>
              <router-link to="/news" class="litop" tag="div">新闻资讯</router-link>
              <div class="foot_line"></div>
            </li>
            <li>
              <router-link to="/hr" class="litop" tag="div">人才招聘</router-link>
              <div class="foot_line"></div>
              <router-link to="/hr" tag="div">人才招聘</router-link>
              <!-- <router-link to="/hr" tag="div">招聘信息</router-link> -->
            </li>
            <li>
              <router-link to="/contact" class="litop" tag="div">联系我们</router-link>
              <div class="foot_line"></div>
            </li>
          </ul>
        </div>
      </div>
      <div class="footbtm">
        <div class="foot_cont content_w">
          <div>{{ webInfo.site_copy }}</div>
           <div>备案号：<a href="https://beian.miit.gov.cn" target="_blank" class="a_color">{{webInfo.miitbeian}}</a> 浙公安网备：{{webInfo.beian}}</div>
        </div>
      </div>
    </div>
  </full-page>
</template>

<script>
import countTo from "vue-count-to";
export default {
  name: "swiperBanner",
  components: {
    countTo,
  },
  data() {
    let that = this;
    return {
      modular_alert:1,
      numList: [],
      news: [],
      webInfo: {},
      fields: [],
      content2: {},
      company: [],
      content1: [],
      content0: {},
      bannerList: [],
      current: 0,
      current1:2,
      num3: 0,
      num2: 0,
      num1: 0,
      balck_color: false, //右边点点显示黑色
      page: "", //当前页
      indexa: 0, //自导航索引
      //整屏滚动参数
      options: {
        licenseKey: "OPEN-SOURCE-GPLV3-LICENSE",
        // controlArrows: false,//箭头消失
        afterLoad: this.afterLoad,
        scrollOverflow: true,
        lockAnchors: true,
        slidesNavigation: true,
        slidesNavPosition: "bottom",
        sectionSelector: ".section",
        slideSelector: ".slide",
        navigation: true, //是否显示导航，默认为false
        navigationPosition: "right", //导航小圆点的位置
        navigationTooltips: [
          "首页",
          "杭实国贸",
          "子公司",
          "业务领域",
          "新闻资讯",
          "联系我们",
        ],
        showActiveTooltip: true,
        scrollBar: false,
        keyboardScrolling: false, //是否可以使用键盘方向键导航，默认为true
        menu: "#menu",
        sectionsColor: ["#313131", "#313131", "#313131"],
        anchors: ["page1", "page2", "page3", "page4", "page5", "page6"],
      },
      height: "100vh",
      innerWidth: window.innerWidth,
      swiperOption: {
        notNextTick: true, //notNextTick是一个组件自有属性，如果notNextTick设置为true，组件则不会通过NextTick来实例化swiper，也就意味着你可以在第一时间获取到swiper对象，假如你需要刚加载遍使用获取swiper对象来做什么事，那么这个属性一定要是true
        // direction: "vertical", //水平方向移动
        // grabCursor: true, //鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状
        setWrapperSize: true, //Swiper使用flexbox布局(display: flex)，开启这个设定会在Wrapper上添加等于slides相加的宽或高，在对flexbox布局的支持不是很好的浏览器中可能需要用到。
        // autoHeight: true, //自动高度。设置为true时，wrapper和container会随着当前slide的高度而发生变化
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。可以设置为数字（可为小数，小数不可loop），或者 'auto'则自动根据slides的宽度来设定数量。loop模式下如果设置为'auto'还需要设置另外一个参数loopedSlides。
        // mousewheel: true, //开启鼠标滚轮控制Swiper切换。可设置鼠标选项，默认值false
        mousewheelControl: true, //同上
        // height: window.innerHeight, // 高度设置，占满设备高度
        resistanceRatio: 0, //抵抗率。边缘抵抗力的大小比例。值越小抵抗越大越难将slide拖离边缘，0时完全无法拖离。本业务需要
        observeParents: true, //将observe应用于Swiper的父元素。当Swiper的父元素变化时，例如window.resize，Swiper更新
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '">' + (index + 1) + "</span>";
          },
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },

        // initialSlide :1,
        updateOnImagesReady: true,
        loop: true,
        autoplay: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        effect: "fade",
        speed: 1500,
        on: {
          init: function (swiper) {
            //Swiper初始化了
            //  播放
            var that = this;
            //
          },
          // 轮播切换后，上一个swiper-slie有视频，则暂停
          slideNextTransitionStart: function () {
            //向后切换
            let index = this.activeIndex;
            //  播放
            //标题文字动画
            $(".bannerText").hide();
            $(".bannerText").removeClass("animate__animated animate__fadeInUp");
            // $(".bannerText").addClass(
            //   "animate__animated animate__fadeOutDown"
            // );

            if ($(".swiper-slide-active").children("div").length > 0) {
              //文字
              setTimeout(function () {
                $(".bannerText").show();
                $(".bannerText").addClass(
                  "animate__animated animate__fadeInUp"
                );
              }, 1000);
            }
          },
          slideChangeTransitionEnd: function () {
            // swiperAnimate(swiper);
            if ($(".swiper-button-prev").children("div").length > 0) {
              //文字
              setTimeout(function () {
                $(".bannerText").show();
                $(".bannerText").addClass(
                  "animate__animated animate__fadeOutUp"
                );
              }, 1000);
            }
          },
        },
      },
      swiperOption1: {
        autoplay:true,
				autoplay: {
					delay: 5000,
					//当用户滑动图片后继续自动轮播
					disableOnInteraction: false,
        },
        paginationClickable: true,
        observer:true,//修改swiper自己或子元素时，自动初始化swiper
        observeParents:true,//修改swiper的父元素时，自动初始化swiper
        initialSlide :1,
        pagination: {
          el: ".swiper-pagination1",
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '">' + (index + 1) + "</span>";
          },
        },
      },
      swiperOptions3:{
        slidesPerView: 2.5,
        loopedSlides: 5,
        // freeMode : true,//是否滑动
				spaceBetween:0,
				// centeredSlides: true,//选中模块是否居中
				//设置点击箭头
				navigation: {
					nextEl: '.swiper3-prev', 
					prevEl: '.swiper3-next'
				},
				//自动轮播
				autoplay:true,
				autoplay: {
					delay: 5000,
					//当用户滑动图片后继续自动轮播
					disableOnInteraction: false,
				},
				//开启循环模式
        loop: true,
        on:{
					slideNextTransitionStart: function(){
            that.swiperIndex = this.realIndex
          },
          slidePrevTransitionStart: function(){
            that.swiperIndex = this.realIndex
          },
        }
			},
    };
  },
  watch: {
    page(newValue, oldValue) {
      if (newValue != oldValue) {
        this.resetSetItem("watchStorage", newValue);
      }
      if (this.page == "page2") {
        $(".mk1").addClass("animate__animated animate__fadeInUp");
        $(".mk_box1").addClass("start_color");
          this.num1 = this.content1[0].title2;
          this.num2 = this.content1[1].title2;
          this.num3 = this.content1[2].title2;
        // this.num2 = 15;
        // this.num3 = 3900;
      
      } else {
        $(".mk1").removeClass("animate__animated animate__fadeInUp");
        $(".mk_box1").removeClass("start_color");
        this.num1 = 0;
        this.num2 = 0;
        this.num3 = 0;
        // this.numList.forEach((k,i)=>{
        //   k.title2 = 0
        // })
        console.log("this.content1", this.numList);
      }
      if (this.page == "page3") {
        $(".mk2").addClass("animate__animated animate__fadeInUp");
        $(".mk_box2").addClass("start_color");
      } else {
        $(".mk2").removeClass("animate__animated animate__fadeInUp");
        $(".mk_box2").removeClass("start_color");
        this.indexa = 0;
      }
      if (this.page == "page4") {
        $(".mk3").addClass("animate__animated animate__fadeInUp");
        $(".mk_box3").addClass("start_color");
      } else {
        $(".mk3").removeClass("animate__animated animate__fadeInUp");
        $(".mk_box3").removeClass("start_color");
      }

      if (this.page == "page5" || this.page == "page6") {
        $("#fp-nav ul").addClass("gray");
        $(".mk4").addClass("animate__animated animate__fadeInUp");
        $(".mk_box4").addClass("start_color");
        
      } else {
        $("#fp-nav ul").removeClass("gray");
        $(".mk4").removeClass("animate__animated animate__fadeInUp");
        $(".mk_box4").removeClass("start_color");
      }
    },
  },
  created() {
    this.web_info();
    this.homepage();
    if(this.innerWidth <= 480){
      this.swiperOptions3.slidesPerView = 2.4;
      this.swiperOptions3.loopedSlides =4.8;
      this.options.navigationTooltips = [];
    }
    if(this.innerWidth <= 320){
      this.swiperOptions3.slidesPerView =1.8;
      this.swiperOptions3.loopedSlides =3.6;
    }
  },
  methods: {
    goNews(id){
     this.$router.push({
        path: "/newsDetail?newsid="+id,
      });
    },
    web_info() {
      var that = this;
      that.$api.web_info({}).then((res) => {
        if (res.status == 1) {
          this.webInfo = res.data;
        } else {
          layer.msg(res.msg);
        }
      });
    },
    goSub(indexa) {
      this.$router.push({
        path: "/subsidiray/0?navctiveIndex="+indexa,
      });
    },
    goAbout() {
      this.$router.push({
        path: "/about/0",
      });
    },
    goNewsList() {
      this.$router.push({
        path: "/news",
      });
    },
    homepage() {
      this.$api.homepage({}).then((res) => {
        if (res.status == 1) {
          this.bannerList = res.data.banner;
          this.content0 = res.data.content0;
          this.content1 = res.data.content1;
          this.numList = res.data.content1;
          this.company = res.data.company;
          this.content2 = res.data.content2;
          this.fields = res.data.fields;
          this.news = res.data.news;
        } else {
          layer.msg(res.msg);
        }
      });
    },
    ImgFn(index) {
      this.current = index;
    },
    ImgFna(index) {
      this.current1 = index;
    },
    afterLoad(origin, destination, direction) {
      this.page = destination.anchor;
    },
    ziFn(e) {
      this.indexa = e;
      this.swiperOption.init();
    },
  },
};
</script>
<style>
.section3_tu{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 97;
}
/* .navActive{
     transition: all 0.3s ;
     transform: scale(1.1);
} */

.swiperBanner {
  position: relative;
}
.section .swiperBanner .swiper-Box {
  position: relative;
  height: 100vh;
}
.swiperBanner .bannerLRImg {
  width: 90%;
}
.swiperBanner .banner-l,
.banner-r {
  width: 8rem;
  height: 8rem;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 1rem;
  z-index: 999;
  top: 30rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: -4rem;
}
.swiperBanner .banner-l:focus {
  outline: none;
}
.swiperBanner .banner-r:focus {
  outline: none;
}
.swiperBanner .banner-l {
  position: absolute;
  left: 0;
}
.swiperBanner .banner-r {
  position: absolute;
  right: 0;
}
.swiperBanner .scroll-l,
.scroll-r {
  position: absolute;
  width: 5rem;
  height: 5rem;
  top: 60%;
  outline: none;
}
.swiperBanner .scroll-l {
  left: -2rem;
  z-index: 1999;
}
.swiperBanner .scroll-r {
  right: -2rem;
  z-index: 1999;
}

.swiperBanner .swiper-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiperBanner .swiper-pagination .swiper-pagination-bullet {
  width: 24px;
  height: 2px !important;
  background-color: #fff;
  /* color: #e60012; */
  opacity: 1;
  border-radius: 0;
}
.section .swiper-pagination-bullet {
  font-size: 14px;
  color: #fff;
}
/* .swiperBanner .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #ffffff;
} */
.section .swiper-pagination {
  text-align: left;
  left: 1.5%;
}
.section .swiper-button-next,
.section .swiper-container-rtl .swiper-button-prev {
  right: auto;
  left: 1.5%;
 
}
.section .swiper-button-prev,
.section .swiper-button-next {
  border: 1px solid rgba(255, 255, 255, 0);
  left: 1.5%;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);

  /* transition:border .3s ease-in-out;
  transition:width .3s ease-in-out;
  transition:height .3s ease-in-out; */
}
.section .swiper-button-prev:hover,
.section .swiper-button-next:hover {
  animation: aaa 0.3s ease-in forwards;
  transform-origin: 50% 50%;
  background: rgba(0, 0, 0, 0);
}

@keyframes aaa {
  0% {
    border: 1px solid rgba(255, 255, 255, 0);
    width: 26px;
    height: 26px;
    left: 1.5%;
  }
  100% {
    border: 1px solid #fff;
    width: 30px;
    height: 30px;
    left: 1.5%;
  }
}
.section .swiper-button-prev {
  top: auto;
  bottom: 32%;
  left: 1.5%;
}
.section .swiper-button-next {
  top: 75%;
}
.section .swiper-button-prev:after,
.section .swiper-button-next:after {
  font-size: 12px;
  font-weight: bold;
  color: #fff;
}
.mk1_tu.fp-scrollable {
  z-index: 99 !important;
}
.imgBox {
  position: relative;
  width: 100%;
  height: 100%;
}
.bannerText {
  position: absolute;
  width: 100%;
  top: 40%;
  /* color: #ffffff;
    text-shadow:rgb(68, 68, 68) 3px 4px 20px; */
  display: none;
  text-align: center;
}
.bannerText span {
  display: block;
  font-size: 0.56rem;
  font-weight: bold;
  color: #fff;
  line-height: 120%;
}
.bannerText .banner_english {
  text-transform: uppercase;
  font-size: 0.36rem;
  font-weight: normal;
  width: 60%;
  margin: 0 auto;
  margin-top: 0.5rem;
}
.bannerText img {
  display: block;
  width: 46%;
  /* width: 7.18rem; */
  margin: 0 auto;
}
.mouse_box {
  width: 0.65rem;
  position: absolute;
  left: 50%;
  margin-left: -0.325rem;
  z-index: 99;
  bottom: 0.3rem;
}
.mouse_box .mouse_line {
  width: 0.01rem;
  margin: 0 auto;
  background: #fff;
  margin-bottom: 0.17rem;
  /* animation: rotatea 3s infinite ease-in-out !important; */
  animation: rotatea 1.5s infinite ease-in 1s;
}
@keyframes rotatea {
  0% {
    opacity: 1;
    height: 0.9rem;
  }
  100% {
    opacity: 0;
    height: 0rem;
  }
}

.mouse_box img {
  width: 0.2rem;
  margin: 0 auto;
  display: block;
  padding-bottom: 0.2rem;
}
.mouse_box .mouse_span {
  color: #fff;
  font-size: 16px;
  text-align: center;
}
.modular_bg {
  display: flex;
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.fullpage-wrapper {
  overflow: hidden !important;
}
.modular2 {
  z-index: 999 !important;
}

.modular2 {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 16px;
}

.modular2_ul li img {
  width: 0.8rem;
  height: 0.8rem;
  margin-right: 0.1rem;
}
.text-style {
  font-size: 0.36rem !important;
}
.modular_num {
  font-size: 0.36rem;
  display: flex;
  justify-content: flex-start;
}
.modular2_box {
  position: absolute;
  top: 18%;
  right: 0;
  left: 0;
  color: #fff;
  z-index: 98;
}
/* .modular2_box .content_w {
  margin-left: calc(10% + 1.22rem);
  width: calc(70% - 1.22rem);
} */
.modular2_txt {
  /* font-family: "TIMES"; */
  font-size: 0.48rem;
  /* margin-bottom: 0.8rem; */
  margin-bottom: 0.6rem;
}
.modular2_con {
  font-size: 16px;
  /* width: 36%; */
  width: 48%;
  line-height: 0.4rem;
  height: 1.8rem;
  text-align: justify;
}
.modular2_ul {
  display: flex;
  justify-content: flex-start;
}
.modular2_ul li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.3rem;
  /* margin-right: .6rem; */
  margin-right: 4.8%;
}
.modular2_ul li span {
  display: block;
  font-size: 16px;
  letter-spacing: 0.01rem;
}

.zgs_bt {
  margin-top: 1rem;
  /* position: absolute;
  bottom:-110%; */
}

.new_bt {
  color: #e60012;
  margin: 0 auto;
  margin-top: 0.6rem;
}
.new_bt .xian {
  background: #e60012;
}
.new_bt .yuan {
  border: 1px solid #e60012;
}
/* .new_bt {
  border-bottom: 1px solid #e60012;
} */

.modular3_cont {
  position: absolute;
  z-index: 100;
}
.modular3_ul {
  display: flex;
  justify-content: flex-start;
  font-weight: bold;
}
.modular3_ul li ,.modular3_ul .modular3_li{
  padding-bottom: 0.1rem;
  margin-right: 6%;
  cursor: pointer;
  font-size: 16px;
}
.mk3_line {
  width: 0;
  height: 2px;
  background: #e60012;
  transition: width 0.3s ease-in-out;
  margin-top: 0.1rem;
}
.modular3_ul li:hover .mk3_line {
  cursor: pointer;
  width: 100%;
}
.modular3_ul .modular3_li:hover .mk3_line{
width: 70%;
}
.modular3_ul .ziActive .mk3_line {
  width: 100%;
}
.modular3_ny {
  width: 100%;
  overflow: hidden;
  /* margin-left: 10%; */
  font-size: 0.16rem;
  color: #fff;
  margin-top: 0.6rem;
}
.modular3_txt {
  font-size: 0.24rem;
  /* margin-top: .26rem; */
}
.modular3_cont .slide {
  margin-top: 1.2rem;
}
.modular3_wz {
  font-size: 14px;
  width: 45%;
  line-height: 0.4rem;
  margin-top: 0.2rem;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.z_index {
  z-index: 999;
}
.modular3_top {
  top: 18%;
  position: relative;
}
.modular5_box .modular2_txt {
  color: #333;
}
.modular5_ul {
  display: flex;
  margin-top: 0.4rem;
}
.modular5_ul li:first-child {
  margin-left: 0rem;
}
.modular5_ul li {
  flex: 1;
  margin-top: 0;
  margin-left: 0.3rem;
  background: #fff;
  box-shadow: 15px 14px 35px rgba(4, 0, 0, 0.1);
  padding: 0.3rem 0.13rem 0.2rem 0.13rem;
  color: #333;
  transition: margin-top 0.3s ease-in-out;
}
.modular5_ul li:hover {
  cursor: pointer;
  margin-top: -0.2rem;
  box-shadow: 15px 14px 35px rgba(4, 0, 0, 0.3);
}
.modular5_ul li:hover .news_txt {
  color: #000;
}
.modular5_ul li:hover .news_time,
.modular5_ul li:hover .news_time span {
  color: #e60012;
}
.news_time {
  cursor: pointer;
  font-size: 0.2rem;
  line-height: 0.24rem;
  color: #666666;
}
.news_time span {
  font-size: 0.16rem;
  color: #999;
  border-bottom: 0.01rem solid #999;
  padding-bottom: 0.05rem;
}
.news_txt {
  font-size: 0.16rem;
  color: #4c4c4c;
  margin-top: 0.3rem;
  margin-bottom: 0.1rem;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.news_cont {
  margin-bottom: 0.2rem;
  font-size: 0.12rem;
  color: #999;
  line-height: 0.24rem;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.news_img {
  width: 100%;
  overflow: hidden;
}
.news_img img {
  width: 100%;
}
#fp-nav.fp-right {
  width: 40px;
  right: 0 !important;
}

#fp-nav ul li a span,
.fp-slidesNav ul li a span {
  width: 50%;
  height: 1px;
  background: rgba(255, 255, 255, 0.5) !important;
  border-radius: 0 !important;
}

#fp-nav ul li,
.fp-slidesNav ul li {
  margin: 0 !important;
  width: auto !important;
}
#fp-nav ul li a.active span,
.fp-slidesNav ul li a.active span,
#fp-nav ul li:hover a.active span,
.fp-slidesNav ul li:hover a.active span {
  width: 100% !important;
  height: 1px !important;
  border-radius: 0 !important;
  background: #fff !important;
  color: rgba(255, 255, 255, 1) !important;
}
#fp-nav ul li:hover .fp-tooltip.fp-right {
  color: rgba(255, 255, 255, 0.5) !important;
}
#fp-nav ul li:hover a span {
  width: 50% !important;
  height: 1px !important;
  border-radius: 0 !important;
}
#fp-nav ul li a span,
.fp-slidesNav ul li a span {
  left: 0% !important;
  margin: 0 !important;
}

.swiper-img {
  display: flex !important;
}

.modular4_box {
  top: 0.8rem;
  bottom: 0;
}
.pos {
  position: relative;
  height: 100%;
}
.modular4_ul {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  height: 100%;
}
.modular4_ul li {
  flex: 1;
  /* border-left: 0.01rem solid rgba(255, 255, 255, 0.1); */
  border-left: 1px transparent solid;
  border-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.05),
      rgba(255, 255, 255, 0.5)
    )
    1 10;
}
.modular4_ul li .modular4_cont {
  position: absolute;
  bottom: 15%;
  left: 0;
  right: 0;
  text-align: center;
  transition: bottom 0.3s ease-in-out;
}
.modular4_ul li:hover .modular4_cont {
  /* cursor: pointer; */
  bottom: 1.6rem;
}
.modular4_ul li:last-child {
  border-right: 0.01rem solid rgba(255, 255, 255, 0.3);
}
.modular4_ul li img {
  display: block;
  width: 0.6rem;
  height: 0.6rem;
  margin: 0 auto;
}
.modular4_cont span {
  display: block;
  margin-top: 0.18rem;
  font-size: 0.24rem;
}
.modular4_cont .modular4_tit {
  width: 70%;
  margin: 0 auto;
  font-size: 0.16rem;
  margin-top: 0.25rem;
  opacity: 0;
  display: none;
  transition: height 3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}
.modular4_ul li:hover .modular4_cont .modular4_tit {
  display: block;
  /* cursor: pointer; */
  opacity: 1;
}
.modular4_ul li .modular4_cont .modular4_tit .line_md4 {
  width: 0;
  margin: 0 auto;
  margin-bottom: 0.3rem;
  height: 0.01rem;
  background: #fff;
  transition: width 0.3s ease-in-out;
}
.modular4_ul li:hover .modular4_cont .modular4_tit .line_md4 {
  width: 100%;
}
.modular4_txt {
  padding-top: calc(18% - 0.8rem);
}

.fp-section.fp-table,
.fp-slide.fp-table {
  height: auto !important;
}
.content_w {
  width: 73%;
}
#fp-nav ul li,
.fp-slidesNav ul li {
  height: 26px !important;
}

.fp-controlArrow {
  margin-left: 5% !important;
  margin-right: 5% !important;
  width: 56px !important;
}
.fp-prev {
  border: none;
  width: 55px;
  height: 108px;
  background-color: rgba(255, 255, 255, 0) !important;
  background: url(../../../static/img/let_jt1.png) !important;
}
.fp-prev:hover {
  background: url(../../../static/img/let_jt2.png) !important;
}
.fp-next {
  border: none;
  width: 55px;
  height: 108px;
  background-color: rgba(255, 255, 255, 0) !important;
  background: url(../../../static/img/right_jt1.png) !important;
}
.fp-next:hover {
  background: url(../../../static/img/right_jt2.png) !important;
}
#fp-nav .gray li a span,
.fp-slidesNav .gray li a span {
  background: rgba(0, 0, 0, 0.5) !important;
  border-radius: 0 !important;
}
#fp-nav .gray li .fp-tooltip {
  color: #000 !important;
}
#fp-nav .gray li a.active span,
.fp-slidesNav .gray li a.active span,
#fp-nav .gray li:hover a.active span,
.fp-slidesNav .gray li:hover a.active span {
  width: 100% !important;
  height: 1px !important;
  border-radius: 0 !important;
  background: #000 !important;
  color: rgba(0, 0, 0, 1) !important;
}
#fp-nav .gray li:hover .fp-tooltip.fp-right {
  color: rgba(0, 0, 0, 0.5) !important;
}
.mk_box1,
.mk_box2,
.mk_box3,
.mk_box4,
.mk_box5 {
  opacity: 0;
  z-index: 99;
}
.start_color,
.start_color1,
.start_color2,
.start_color3,
.start_color4 {
  opacity: 1;
}

@keyframes scaleBigToSmall {
  0% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

.scaleBigToSmall {
  -webkit-animation-name: scaleBigToSmall;
  animation-name: scaleBigToSmall;
  transform-origin: 50% 50%;
}

.section .swiperBanner .swiper-slide-active .imgBox,
.fp-section.active .modular_bg,
.aa {
  -webkit-animation: scaleBigToSmall 4s;
  animation: scaleBigToSmall 4s;
}
.section .swiper-pagination-fraction,
.section .swiper-pagination-custom,
.section .swiper-container-horizontal > .section .swiper-pagination-bullets {
  bottom: -20px !important;
}
.section .swiper-pagination-bullets {
  margin-top: 1rem;
}
.section .swiperBanner .swiper-pagination .swiper-pagination-bullet {
  background: rgba(255, 255, 255, 1) !important;
}
/* .swiperBanner .swiper-pagination .swiper-pagination-bullet-active {
  background: #ffff !important;
} */
.footul li div:hover {
  color: #fff;
}
#fp-nav ul li .fp-tooltip.fp-right {
  top: 3px;
  right: 52px !important;
}

.section .swiper-pagination-fraction,
.section .swiper-pagination-custom,
.section .swiper-container-horizontal > .section .swiper-pagination-bullets {
  width: auto !important;
  left: 10%;
  bottom: 0rem !important;
}
.zi_bt {
  margin-top: 0.1rem;
  /* margin-bottom: 10%; */
}
/* .zi_swiper  */
.section .swiper-pagination-bullet {
  width: 24px;
  height: 2px;
  background: rgba(255, 255, 255, 1);
  color: rgba(255, 255, 255, 1);
  border-radius: 0;
  text-align: center;
  opacity: 1;
}

.section .swiper-pagination-bullet-active {
  position: relative;
  color: #e60012;
}

.section .swiper-pagination-bullet-active::before {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  height: 2px;
  content: "";
  background: #e60012;
  animation: aa 0.3s ease-in forwards;
}

.section
  .swiper-container-horizontal
  > .section
  .swiper-pagination-bullets
  .section
  .swiper-pagination-bullet:after {
  margin-bottom: 0.5rem;
}
.zi_gs .swiper-slide {
  height: 80% !important;
}
@keyframes aa {
  0% {
    width: 0;
  }
  100% {
    width: 24px;
  }
}
.mt18 {
  margin-top: 0%;
}
.mt15 {
  top: 15%;
}
.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  line-height: 32px;
}
.fp-scrollable {
  position: relative;
}

@media screen and (max-width: 2680px) and (min-width: 1560px) {
  /* .swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction{
     margin-top:6%!important;
    } */
}
@media (max-width: 1560px) {
  .modular2_con{
    width: 58%;
  }
  .bannerText .banner_english {
    width: 75%;
  }
  
}
@media (max-width: 1400px) {
  .modular2_con{
    width: 62%;
  }
  .bannerText .banner_english {
    width: 80%;
  }
}
@media (max-width: 1200px) {
  .modular2_con{
    width: 70%;
  }
  .bannerText .banner_english {
    width: 80%;
    font-size: .3rem;
  }
  .bannerText span{
    font-size: .48rem;
  }
}
@media (max-width: 1024px) {
  .modular2_con{
    width: 80%;
  }
  .modular3_wz{
    width: 100%;
  }
  .content_w{
    width: 80%;
  }
  .modular3_ul li{
    margin-right:4%;
  }
  .bannerText .banner_english {
    width: 80%;
    font-size: .3rem;
  }
  .bannerText span{
    width: 80%;
    margin: 0 auto;
    font-size: .44rem;
  }
}
@media (max-width: 768px) {
.bannerText span{
  font-size: .4rem;
}
.bannerText .banner_english{
  font-size: .32rem;
}
.modular2_ul li img{
  width: 0.7rem;
  height: 0.7rem;
}
.modular_num .text-style{
  font-size: .32rem!important;
}
.modular_num span:last-child{
  font-size: .3rem!important;
  margin-top: 0!important;
}
.modular2_con {
    width: 88%;
}

.modular4_ul li:hover .modular4_cont .modular4_tit{
  display: none;
}
/* .modular5_ul{
  flex-direction: row;
  flex-wrap: wrap;
} */
.modular5_ul li:nth-last-child(2),.modular5_ul li:nth-last-child(1){
  display: none;
}
.modular2_txt{
  font-size: .4rem;
}

 .bannerText .banner_english {
    width: 95%;
    font-size: .24rem;
  }
  .bannerText span{
    width: 90%;
    margin: 0 auto;
    font-size: .30rem;
  }
}
@media (max-width: 480px) {
  .mouse_box{
    width: 1.5rem;
  }
  .bannerText{
    top: 35%;
  }
  .modular2_con{
    font-size: 14px;
    height: 3rem;
    line-height: 24px;
  }
  .modular2_ul li{
    display: block;
    margin-right: 15%;
  }
  .modular2_bt img{
    margin-top: -2px;
    margin-right: -9px;
  }
  .modular2_bt:hover .yuan{
    right: -15px;
    
  }
  .xian{
    right: -6px;
    margin-top: 1px;

  }
  .swiper-Box{
    height: auto;
  }
  .modular2_txt{
    font-size: .48rem;
  }
  .modular3_ul .modular3_li:hover .mk3_line{
    width: 65%;
  }
  .modular3_wz{
    line-clamp: 5;
    -webkit-line-clamp: 5;
  }
  .section .zi_bt{
    margin-top: .7rem;
  }
  .zi_gs .swiper-slide{
    margin-top: .4rem;
  }
  .modular3_wz{
    line-height: 24px;
  }
  .modular5_box{
    width: 85%;
  }
  .news_time{
    font-size: 16px;
  }
  .news_time span{
    display: block;
    font-size: 14px;
    margin-top: .1rem;
  }
  .news_time span{
    padding-bottom: .2rem;
    border-bottom: 0.01rem solid #eee;

  }
  .news_txt{
    font-size: 14px;
  }
  .news_cont{
    font-size: 12px;
    line-height: 24px;
  }
  .new_bt{
    margin-top: 1.5rem;
  }
  .bannerText span{
    line-height: 180%;
  }
  .lines{
		margin-top:4px;
	}
	.footul{
		display: none;
	}
	.footer .footlt{
		width: auto;
		display: flex;
		justify-content: space-between;
	}
	.footer .footlogo img{
		width: 2rem;
	}
	.footma_ul{
		flex: 1;
		margin-left: .5rem;
	}
	.footma_ul li{
		line-height: 24px;
	}
	.footma_ul li img{
		width: .35rem;
		height: .3rem;
		margin-right: .2rem;
		margin-top: 2px;
	}
	.foot_cont div{
		width: 100%;
		text-align: center;
	}
	.foot_cont div:last-child{
		display: none;
	}
	.footbtm{
		height: .6rem;
		line-height: .6rem;
  }
  .footbox .content_w{
    width: 90%;
  }
  #fp-nav.fp-right{
    width: 0px;
  }
  .fp-tableCell{
    overflow: hidden;
  }
  /* #fp-nav ul li a span, .fp-slidesNav ul li a span{
    width: 40%;
  } */
  .bannerText span{
    width: 84%;
  }
  .modular3_li{
     white-space:nowrap;
     /* width: 50%; */
  }
  .sub_box{
    height: 50vh;
    position: relative;
    z-index: 2;
  }
  .sub_box1{
    z-index: 1;
  }
  .modular4_ul li{
    border-image:none;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    overflow: hidden;
  }
  .section .swiperBanner .swiper-slide-active .imgBox, .fp-section.active .modular_bg, .aa{
    animation: scaleBigToSmall 2s;
  }
  .mk3_tu_box{

    overflow: hidden;
  }
  .sub_box .mk3_tu{
    height: 50vh;
  }
  .sub_box .modular4_box{
    top: 0;
  }
  .modular3_ul .swiper-slide:last-child{
     margin-left: .2rem;
     margin-right:1rem;
  }
  .ph_subimg{
    overflow: hidden;
  }
  .modular4_ul li .modular4_cont{
    bottom: 40%;
  }
  .modular4_ul li:not(.modular4_cont):hover .modular4_cont{
    bottom: 45%;
  }
  .modular2_box .modular3_ul{
    width: 120%;
  } 
   .modular3_ul .ziActive .mk3_line{
   width: 20%;
  }
  .modular3_ul .modular3_li:hover .mk3_line{
    width: 20%;
  }
  .modular_bgtu1{
    position:absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    /* z-index: -1; */
  } 
  .modular2_box,.sub_box_box{
    z-index: 99;
  }
  .news_cont{
    line-height: 18px;
  }
  .mk_box4{
    z-index: 100;
  }
}
@media (max-width: 375px) {
 .modular2_bt img{
    margin-top: -2px;
    margin-right: -9px;
  }
  .modular2_bt:hover .yuan{
    right: -15px;
    
  }
  .xian{
    right: -6px;
    margin-top: 0;
  }
  .modular2_ul li{
    margin-right: 12%;
  }
  .modular2_con{
    height: 3.4rem;
  }

}
@media (max-width: 320px) {
  .modular2_bt img{
    margin-top: -2px;
    margin-right: -22px;
  }
  .modular2_bt:hover .yuan{
    right: -28px;
    
  }
  .xian{
    right: -20px;
    /* margin-top: -1px; */
  }
  .new_bt .xian{
    margin-top: -1px;
  }
  .modular2_con{
    height: 4.4rem;
  }
  .modular2_ul li {
    margin-right: 9%;
  }
}


</style>